.wrapper {
  max-width: 1280px;
  padding: 0 10px 50px 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.main-text {
  font-family: "Inter", sans-serif;
  color: #124872;
  font-size: 45px;
  font-weight: 400;
}

.parentsofpreschoolers {
  gap: 33px;
  display: flex;
}

.router-container {
  display: flex;
  font-size: 12px;
  color: #8f8f8f;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  .route-link {
    color: #8f8f8f;
    text-decoration: none;
    cursor: pointer;
  }

  .chosen-route {
    color: #333333;
  }
}

.chapters-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.chapter-block {
  background-color: white;
  cursor: pointer;
  width: 343px;
  color: #333333;
  padding: 13px 20px;
  border: 2px solid #335d8c;
  font-family: "Roboto";
  text-decoration: none;
  font-size: 21px;
  text-align: left;

  &.selected {
    background: #335d8c;
    color: #ffffff;
  }
}

.article-image {
  width: 100%;
  height: 476px;
  object-fit: cover;
}

.sub-sections-container {
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
}

.sub-section-block {
  display: flex;
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  overflow: hidden;

  .sub-section-block-text {
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 500;
    font-size: 30px;
    padding: 0 50px;
    font-family: "Inter", sans-serif;
    color: white;
    flex: 1;
    height: 100%;
    background-color: #3a6aa1;
  }

  .sub-section-image {
    width: 374px;
    height: 271px;
    object-fit: cover;
  }
}

.article {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  font-family: "Roboto";
  color: #333333;
  gap: 20px;
}

.authors {
  font-weight: 400;
  font-size: 18px;
  font-family: "Roboto";
}

.article-title {
  font-weight: 400;
  font-size: 32px;
  font-family: "Roboto";
}

.link-block {
  background-color: #eeeeee;
  padding: 15px 25px;

  a {
    color: #3a6aa1;
  }
}

.article-text {
  font-family: "Roboto", sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
}

.get-back-button {
  color: #335d8c;
  cursor: pointer;
  width: 65px;
}

.articles-container {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

// SubSectionsDrawer //

.dropdown {
  border: 1px solid #335d8c;
  border-radius: 5px;
}

.dropdown-text {
  color: #335d8c;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  padding: 10px 12px;
  cursor: pointer;
  height: auto;
}

.dropdown-text:hover {
  color: #335d8c;
}

.dropdown-text:after {
  content: "";
  transition: all 0.3s;
  border: solid #335d8c;
  margin-top: 8px;
  margin-right: 6px;
  float: right;
  padding: 5px;
  transform: rotate(-135deg);
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(-135deg);
}

.dropdown.active .dropdown-text:after {
  margin-top: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown-items {
  position: relative;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.1s ease;
}

.dropdown.active .dropdown-items {
  visibility: visible;
  color: black;
  height: auto;
  max-height: 290px;
  opacity: 1;
}

.dropdown-item {
  cursor: pointer;
  color: #8f8f8f;
  -moz-user-select: none;
  -khtml-user-select: none;
  text-decoration: none;
  user-select: none;
  padding: 10px 12px;
}

.dropdown-item:hover {
  color: #335d8c;
  padding: 10px 14px;
}
